#about {
  padding: 282.0402rem 0 282.0402rem $sidePadding;
  .row {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    .col {
      flex: 0 0 883.7261rem;
      width: 883.7261rem;
      max-width: 883.7261rem;
      margin-right: 188.0268rem;
      h2 {
        font-family: $eksellDisplaySmall;
        font-size: 92.1331rem;
        line-height: 1.2;
        margin-bottom: 47.0067rem;
      }
      p {
        color: #78787a;
        font-size: 35.7251rem;
      }
      h3 {
        font-family: $eksellDisplaySmall;
        font-size: 56.408rem;
        line-height: 1.2;
        margin: 56.408rem 0;
      }
      .skills-wrapper {
        ul {
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          align-items: center;
          justify-content: flex-start;
          li {
            flex: 0 0 131.6188rem;
            width: 131.6188rem;
            max-width: 131.6188rem;
            height: 131.6188rem;
            margin: 0 75.2107rem 37.6054rem 0;
          }
        }
      }
    }
    .video-wrapper {
      flex: 0 0 calc(100% - 883.7261rem - 188.0268rem);
      max-width: calc(100% - 883.7261rem - 188.0268rem);
      width: calc(100% - 883.7261rem - 188.0268rem);
      height: 100%;
      overflow: hidden;
      opacity: 0.9;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        @include borderRadius(40% 60% 70% 30% / 40% 50% 60% 50%);
        animation: morph 7s linear infinite;
        pointer-events: none;
        &::-webkit-media-controls {
          display: none;
        }
      }
    }
  }
}

@keyframes morph {
  0%,
  100% {
    @include borderRadius(40% 60% 70% 30% / 40% 40% 60% 50%);
  }
  34% {
    @include borderRadius(70% 30% 50% 50% / 30% 30% 70% 70%);
  }
  67% {
    @include borderRadius(100% 60% 60% 100% / 100% 100% 60% 60%);
  }
}
