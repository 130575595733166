#masthead {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 75.2107rem $sidePadding;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  transition: all ease 0.4s;
  &.sticky {
    padding: 9.4013rem $sidePadding;
    background: #fff;
    box-shadow: 0 0 30px rgb(0 0 0 / 14%);
    .logo-wrapper {
      width: 131.6188rem;
      height: 131.6188rem;
    }
  }
  .logo-wrapper {
    width: 188.0268rem;
    height: 188.0268rem;
    transition: all ease 0.4s;
  }
  .main-menu {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      padding: 0 37.6054rem;
      a {
        &:not(.btn) {
          font-size: 33.8448rem;
          color: #999;
          font-weight: 600;
        }
        position: relative;
        &.btn {
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -3.7605rem;
          width: 100%;
          height: 3.7605rem;
          background: $purple;
          @include transform(scaleX(0));
          transform-origin: right;
          -webkit-transform-origin: right;
          -moz-transform-origin: right;
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        &:hover,
        &.current {
          color: $purple;
          &::after {
            @include transform(scaleX(1));
            transform-origin: left;
            -webkit-transform-origin: left;
            -moz-transform-origin: left;
          }
        }
      }
    }
  }
  .social-media-wrapper {
    position: absolute;
    right: 0;
    @include ver-center();
    width: $sidePadding;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      li {
        &:not(:last-of-type) {
          margin-right: 47.0067rem;
        }
        a {
          width: 65.8094rem;
          height: 65.8094rem;
          transition: 0.3s;
          color: #999;
          &:hover {
            color: $purple;
            @include transform(scale(1.1));
          }
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
