#site-footer {
  padding: 0 $sidePadding;
  margin-top: 169.2241rem;
  .container {
    @include borderRadius(90.2529rem);
    box-shadow: 0 0 46px rgb(0 0 0 / 5%);
    background: rgba(255, 255, 255, 0.9);
    padding: 169.2241rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    .col {
      &.deco-imgs {
        img {
          position: absolute;
          z-index: 1;
          pointer-events: none;
          &:nth-of-type(1) {
            top: -336.8295rem;
            left: -545.2778rem;
            width: 1165.7663rem;
          }
          &:nth-of-type(2) {
            top: 897.7395rem;
            right: -131.6188rem;
            width: 750.227rem;
          }
        }
      }
    }
    .mail {
      position: absolute;
      right: 68.8027rem;
      font-size: 33.8448rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      align-content: center;
      @include ver-center();
      color: $purple;
      &:hover {
        text-decoration: underline;
      }
      svg {
        margin-right: 15.0421rem;
      }
    }
    .form-container {
      flex: 0 0 1541.82rem;
      width: 1541.82rem;
      max-width: 1541.82rem;
    }
    .social-bar {
      flex: 0 0 100%;
      width: 100%;
      margin-top: 65.8094rem;
      ul {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        li {
          flex: 0 0 33%;
          width: 33%;
          text-align: center;
          height: 263.2376rem;
          &:not(:last-of-type) {
            border-right: 2px solid rgb(17 16 41 / 10%);
          }
          a {
            height: 100%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            align-items: center;
            justify-content: center;
            font-size: 33.8448rem;
            font-weight: 600;
            color: #9f9fa9;
            @include transform(scale(1));
            @include borderRadius(28.204rem);
            @include transit(all ease 0.3s);
            &:hover {
              @include transform(scale(1.2));
              color: #000;
              box-shadow: 0 18.8027rem 56.408rem rgb(0 0 0 / 11%);
              background: #fff;
              z-index: 1;
            }
            svg {
              margin-right: 15.0421rem;
            }
          }
        }
      }
    }
  }
  .copy-bar {
    padding: 65.8094rem 0;
    p {
      font-size: 33.8448rem;
    }
  }
}
