.timeline-wrapper {
  h2 {
    font-family: $eksellDisplaySmall;
    font-size: 92.1331rem;
    line-height: 1.2;
    margin-bottom: 84.6121rem;
    text-align: center;
  }
  ul {
    .MuiTypography-root {
      font-size: 54.5278rem;
      font-family: $gilroy;
    }
    .MuiTimelineDot-root,
    .MuiTimelineConnector-root {
      background-color: $purple;
    }
    li {
      p {
        color: $purple;
      }
      &:nth-of-type(2n) {
        .content-wrapper {
          justify-content: flex-end;
          .content {
            p {
              margin-left: auto;
              margin-right: 0;
            }
          }
        }
      }
      img {
        width: 282.0402rem;
        height: 131.6188rem;
        object-fit: contain;
        object-position: center;
      }
      .content-wrapper {
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 65.8094rem;
        .content {
          margin-left: 47.0067rem;
          h3 {
            font-size: 92.1331rem;
            font-family: $eksellDisplaySmall;
          }
          p {
            font-size: 33.8448rem;
            color: #78787a;
            max-width: 752.1073rem;
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
    }
  }
}
