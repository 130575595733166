.btn {
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-size: 33.8448rem;
  padding: 45.6475rem 94.0134rem;
  &-small {
    padding: 28.204rem 64.0134rem;
  }
  &-xs {
    padding: 18.8027rem 28.204rem;
    font-size: 26.3238rem;
  }
  &-primary {
    @include borderRadius(120.3372rem);
    letter-spacing: 4pt;
    color: white;
    background: linear-gradient(to bottom right, #725cf0, #9683c2);
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 79%);
    transition: all ease 0.8s;
    margin: 0 0;
    display: inline-block;
    background-size: auto 3008.4292rem;
    > span {
      z-index: 103;
      position: relative;
    }
    .gradient {
      cursor: pointer;
      opacity: 1;
      transition: all ease 0.8s;
      content: "";
      position: absolute;
      border-radius: 100%;
      width: 200%;
      height: 300%;
      z-index: 0;
      background: radial-gradient(
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      );
      top: 131.6188rem;
      left: -225.6322rem;
    }
    &:hover {
      &:not(&-xs) {
        box-shadow: 0px 18.8027rem 65.8094rem -28.204rem rgb(0 0 0 / 79%);
        background-size: auto 131.6188rem;
        .gradient {
          top: -131.6188rem;
          left: -225.6322rem;
          opacity: 0.7;
        }
      }
    }
  }
}
