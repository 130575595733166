@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-Light.eot');
    src: url('Gilroy-Light.eot?#iefix') format('embedded-opentype'),
        url('Gilroy-Light.woff2') format('woff2'),
        url('Gilroy-Light.woff') format('woff'),
        url('Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('Gilroy-ExtraBold.eot');
    src: url('Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Gilroy-ExtraBold.woff2') format('woff2'),
        url('Gilroy-ExtraBold.woff') format('woff'),
        url('Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

