.form-container {
  h3 {
    font-size: 48.887rem;
    color: $purple;
    margin-bottom: 18.8027rem;
    text-align: center;
    font-weight: 800;
  }
  h2 {
    font-size: 92.1331rem;
    color: #000;
    font-family: $eksellDisplaySmall;
    margin-bottom: 18.8027rem;
    text-align: center;
  }
  p {
    font-size: 33.8448rem;
    color: #999;
    margin-bottom: 65.8094rem;
    text-align: center;
  }
  form {
    .form-control {
      margin-bottom: 47.0067rem;
      input,
      textarea {
        background: rgba(244, 244, 244, 0.801191);
        border-radius: 20.683rem;
        // height: 135.3793rem;
        font-size: 33.8448rem;
        padding: 47.0067rem 56.408rem;
        border: 0;
        transition: all ease 0.4s;
        background-repeat: no-repeat;
        background-position: 95% 47.0067rem;
        width: 100%;
        box-shadow: none;
        &:focus {
          box-shadow: 0 0 0 3.7605rem #ffa700 inset;
        }
      }
      textarea {
        resize: vertical;
        transition: resize 0s;
        font-family: $gilroy;
        min-height: 282.0402rem;
      }
      .btn {
        margin: 0 auto;
        display: block;
      }
    }
  }
}
