#projects {
  padding: 169.2241rem $sidePadding;
  .filter-bar {
    width: fit-content;
    width: -moz-fit-content;
    margin: 0 auto 84.6121rem auto;
    border-radius: 10px;
    @include borderRadius(18.8027rem);
    background: #f7f7f7;
    box-shadow: 94.0134rem 94.0134rem 188.0268rem #bebebe,
      -94.0134rem -94.0134rem 188.0268rem #ffffff;
    padding: 18.8027rem;
    border: 2px solid #fff;
    ul {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      li {
        &:not(:last-of-type) {
          margin-right: 47.0067rem;
        }
        a {
          font-size: 30.0843rem;
          background: #f7f7f7;
          box-shadow: 18.8027rem 18.8027rem 94.0134rem #bebebe,
            -18.8027rem -18.8027rem 94.0134rem #ffffff;
          display: block;
          padding: 18.8027rem 28.204rem;
          @include borderRadius(18.8027rem);
          color: #000;
          text-transform: uppercase;
          font-weight: 700;
          &.active {
            color: #fff;
            background: $purple;
          }
        }
      }
    }
  }
  .projects-list {
    display: flex;
    flex-wrap: wrap;
    transition: all ease 0.4s;
    min-height: 658.0939rem;
    .loading-icon {
      display: none;
      position: absolute;
      @include hor-center();
      top: 188.0268rem;
      font-size: 159.8228rem;
      color: $purple;
      z-index: 9;
      -webkit-animation: rotate-scale-up 1s linear infinite both;
      animation: rotate-scale-up 1s linear infinite both;
    }
    &.loading {
      position: relative;
      cursor: wait;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 1;
        cursor: wait;
        opacity: 0.5;
      }
      .loading-icon {
        display: block;
      }
    }
    .col {
      flex: 0 0 auto;
      width: 33.3333333333%;
      padding: 0 28.204rem;
      .project-card {
        margin: 0 0 56.408rem 0;
      }
    }
    .no-projects {
      flex: 0 0 100%;
      width: 100%;
      h2 {
        text-align: center;
        font-size: 50.1686rem;
        color: #000;
        margin: 56.408rem 0;
        font-weight: 600;
      }
    }
  }
}
@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
    transform: scale(2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}
@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
    transform: scale(2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}
