.animatedparticles {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: -1;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f8f6f9;
    opacity: 0.3;
    z-index: 0;
  }
  &::after {
    position: absolute;
    content: "";
    width: calc(100% - 940.1341rem);
    background: rgb(248 246 249 / 92%);
    margin: 0 auto;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    margin: 0 auto;
    filter: blur(50px);
  }
  img {
    position: absolute;
    width: 376.0537rem;
    z-index: -1;
  }
}
