* {
  font-weight: normal;
}
html {
  font-size: 0.0277vw;
  font-family: $gilroy;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}
// ::selection {
//   color: #a67744;
//   background: rgba(255, 255, 255, 0.99);
// }

body {
  font-size: 32rem;
  font: 9pt/1.5em $gilroy;
  font-weight: 400;
  line-height: 1.2;
  color: #151515;
  text-align: left;
  margin: 0;
  transition: 0.6s;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal !important;
  border: 0;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
