@font-face {
    font-family: 'Eksell Display Stencil';
    src: url('EksellDisplay-Stencil.eot');
    src: url('EksellDisplay-Stencil.eot?#iefix') format('embedded-opentype'),
        url('EksellDisplay-Stencil.woff2') format('woff2'),
        url('EksellDisplay-Stencil.woff') format('woff'),
        url('EksellDisplay-Stencil.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eksell Display Small';
    src: url('EksellDisplaySmall.eot');
    src: url('EksellDisplaySmall.eot?#iefix') format('embedded-opentype'),
        url('EksellDisplaySmall.woff2') format('woff2'),
        url('EksellDisplaySmall.woff') format('woff'),
        url('EksellDisplaySmall.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eksell Display';
    src: url('EksellDisplayMedium.eot');
    src: url('EksellDisplayMedium.eot?#iefix') format('embedded-opentype'),
        url('EksellDisplayMedium.woff2') format('woff2'),
        url('EksellDisplayMedium.woff') format('woff'),
        url('EksellDisplayMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eksell Display Large';
    src: url('EksellDisplay-Large.eot');
    src: url('EksellDisplay-Large.eot?#iefix') format('embedded-opentype'),
        url('EksellDisplay-Large.woff2') format('woff2'),
        url('EksellDisplay-Large.woff') format('woff'),
        url('EksellDisplay-Large.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

