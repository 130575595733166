#hero {
  margin-top: 206.8295rem;
  padding: 0 calc($sidePadding / 2) 0 $sidePadding;
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    .col {
      flex: 0 0 874.3247rem;
      max-width: 874.3247rem;
      width: 874.3247rem;
      h1 {
        font-family: $eksellDisplaySmall;
        font-size: 92.1331rem;
        line-height: 1.2;
        margin-bottom: 47.0067rem;
        span {
          color: $purple;
        }
      }
      p {
        color: #78787a;
        font-size: 48.887rem;
      }
      .btn {
        margin-top: 75.2107rem;
      }
    }
    .img-wrapper {
      flex: 0 0 1880.2683rem;
      max-width: 1880.2683rem;
      width: 1880.2683rem;
    }
  }
}
