#project-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  &.open {
    display: flex;
  }
  .modal-content {
    background-color: #fff;
    width: 90%;
    height: 90%;
    @include borderRadius(90.2529rem);
    box-shadow: 0 0 46px rgb(0 0 0 / 16%);
    padding: 84.6121rem;
  }
}
