.project-card {
  padding: 18.8027rem;
  @include borderRadius(56.408rem);
  cursor: pointer;
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  background: rgb(255 255 255 / 27%);
  backdrop-filter: blur(5px);
  overflow: hidden;
  box-shadow: 0px 100px 99px #9784e81f;
  border: 2px solid #fff;
  transition: all ease 0.4s;
  &:hover {
    box-shadow: 0px 0px 75.2107rem #735df0;
    @include transform(scale(1.05));
  }
  &-image {
    height: 564.0805rem;
    @include bg-props(cover, center, no-repeat);
    @include borderRadius(37.6054rem);
    padding: 0;
    border: 1px solid #fff;
  }
  &-content {
    margin: 37.6054rem 0 75.2107rem 0;
    ul {
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      margin-bottom: 30.0843rem;
      li {
        font-size: 30.0843rem;
        color: $purple;
        font-weight: 700;
        &:not(:last-of-type) {
          margin-right: 28.204rem;
          padding-right: 28.204rem;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: -1px;
            transform: translateY(-50%);
            width: 1px;
            height: 100%;
            background: $purple;
          }
        }
      }
    }
    h3 {
      font-size: 45.1264rem;
      color: #999;
      font-weight: 500;
      letter-spacing: 0.03em;
      font-family: $eksellDisplaySmall;
    }
    .btn {
      margin-top: 28.204rem;
    }
  }
}
